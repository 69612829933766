<template>
  <div>
    <main class="main product-details-main">
      <PagesPath
          :pathTitle1="'پست ها'"
          :pathTo1="'/weblog-list'" />

      <div class="weblog-list-grid container text-right mt-md-5 mt-3">
        <div class="weblog-list-main">
          <b-skeleton v-if="status"></b-skeleton>
          <div v-else>
            <!-- باکس جستوجو پست -->
            <form @submit.prevent="blogSearch" id="seatchBlogSection">
              <button
                type="button"
                class="d-md-inline-block d-none bg-none ml-2"
              >
                <svg
                  width="27"
                  height="27"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="11.7666"
                    cy="11.7666"
                    r="8.98856"
                    stroke="#444"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.0183 18.4851L21.5423 22"
                    stroke="#444"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <input type="text" id="seacrchInBlogs" v-model="search" />
              <b-spinner
                v-if="spinnerSearch"
                type="grow"
                label="Spinning"
              ></b-spinner>
            </form>
            <hr id="hrSearch" />
          </div>
          <h4 class="mb-4">لیست مطالب</h4>

          <div v-if="status">
            <b-row>
              <b-col>
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton-img></b-skeleton-img>
                <br />
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton></b-skeleton>
              </b-col>
              <b-col>
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton-img></b-skeleton-img>
                <br />
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton></b-skeleton>
              </b-col>
              <b-col>
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton-img></b-skeleton-img>
                <br />
                <b-skeleton></b-skeleton>
                <br />
                <b-skeleton></b-skeleton>
              </b-col>
            </b-row>
          </div>
          <section v-else class="weblog-list-main-grid">
            <template v-if="blogs.length != 0">
              <Weblog
                v-for="(weblog, index) in blogs.posts.data"
                :key="index"
                :weblog="weblog"
              ></Weblog>
            </template>
            <div v-else>
              <span>پستی برای نمایش وجود ندارد</span>
            </div>
          </section>
          <b-skeleton v-if="status"></b-skeleton>
          <section
            id="mainPaginationBlog"
            v-else
            class="weblog-list-pagination text-center my-4 p-2 bg-color-fa"
          >
            <section
              v-if="blogs && blogs.posts != ''"
              id="listPagination"
              class="
                list-pagination
                weblog-list-pagination
                text-center
                my-4
                py-2
              "
            >
              <pagination
                :limit="1"
                :data="blogs.posts"
                @pagination-change-page="changePage"
                :disabled="disabled"
                class="justify-content-center"
              >
                <span slot="prev-nav">
                  <ZPrevSvg />
                </span>
                <span slot="next-nav">
                  <ZNextSvg />
                </span>
              </pagination>
            </section>
            <!-- <pagination :data="blogs.posts.links" :router="true" @pagination-change-page="changePage"></pagination> -->
          </section>
          <!-- <section
            class="
              list-next-prev-btn
              d-flex
              flex-wrap
              justify-content-between
              mb-3
              d-lg-none
            "
          >
          <b-skeleton v-if="status" type="button"></b-skeleton>
            <button
            v-else
              class="
                d-flex
                justify-content-center
                bg-color-ea
                p-2

                mb-2
                w-49
              "

              :class="{'disableLink':blogs.posts.prev_page_url==null}"
              @click.prevent="showBlogs(blogs.posts.prev_page_url)"
            >
              <span class="mr-2 fontsize14 weight-bold text-color-444 py-2"
                >صفحه قبلی</span
              >
            </button>
          <b-skeleton v-if="status" type="button"></b-skeleton>

            <button
            v-else
              :class="{'disableLink':blogs.posts.next_page_url==null}"
              @click.prevent="showBlogs(blogs.posts.next_page_url)"
              class="
                d-flex
                justify-content-center
                bg-color-ea
                p-2

                mb-2
                w-49
              "
            >
              <span class="mr-2 fontsize14 weight-bold text-color-444 py-2">
                صفحه بعدی</span
              >
            </button>
          </section> -->
        </div>
        <div class="welog-list-side">
          <b-skeleton-img v-if="status"></b-skeleton-img>
          <br v-if="status" />

          <section
            v-else
            class="
              weblog-categories

              box-shaddow20
              p-3
              bg-white
              mb-3
            "
          >
            <!-- دسته بندی پست ها -->
            <div class="d-flex border-bottom-dashed pb-2">
              <h6 class="mr-2 weight-bold text-color-444 fontsize-medium ">دسته بندی ها</h6>
            </div>
            <ul class="py-3">
              <!-- دریافت از کامپیوت پایین -->
              <li
                v-for="(category, index) in blogs.category"
                :key="'category' + index"
              >
                <router-link class="fontsize-small"
                  :to="{
                    name: 'weblogList',
                    params: { id: category.id, slug: category.slug },
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="11"
                    fill="currentColor"
                    class="bi bi-chevron-left"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                    />
                  </svg>
                  {{ category.name }}
                </router-link>
              </li>
            </ul>
          </section>
          <!-- <section
            class="
              advertisting1
              bg-color-ea

              overflow-hidden
              box-shaddow20
              text-center
              mb-3
            "
          >
            <a href=""><img src="@/assets/images/banner1.png" alt="" /></a>
          </section> -->

          <!--
          <section

            class="
              weblog-last-articles

              box-shaddow20
              p-3
              bg-white
              mb-3
            "
          >
            <div class="d-flex border-bottom-dashed pb-2 mb-3">
              <h5 class="mr-2 fontsize18 weight-bold text-color-444">
                آخرین مطالب
              </h5>
            </div>
            <article>
              <a class="d-flex mb-3" href="">
                <div class="weblog-last-articles-img ml-1">
                  <img src="@/assets/images/art3.jpg" alt="" />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="fontsize15 text-color-666">
                    انتخاب لباس های مناسب مسافرت خارجی
                  </h6>
                  <div
                    class="d-flex justify-content-between align-items-baseline"
                  >
                    <span class="fontsize12 text-color-999">شنبه 12 مرداد</span>
                    <span class="text-color-999">/</span>
                    <button class="fontsize13 text-color-theme bg-none">
                      اطلاعات بیشتر...
                    </button>
                  </div>
                </div>
              </a>
            </article>
            <article>
              <a class="d-flex mb-3" href="">
                <div class="weblog-last-articles-img ml-1">
                  <img src="@/assets/images/art2.jpg" alt="" />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="fontsize15 text-color-666">
                    انتخاب لباس های مناسب مسافرت خارجی
                  </h6>
                  <div
                    class="d-flex justify-content-between align-items-baseline"
                  >
                    <span class="fontsize12 text-color-999">شنبه 12 مرداد</span>
                    <span class="text-color-999">/</span>
                    <button class="fontsize13 text-color-theme bg-none">
                      اطلاعات بیشتر...
                    </button>
                  </div>
                </div>
              </a>
            </article>
            <article>
              <a class="d-flex mb-3" href="">
                <div class="weblog-last-articles-img ml-1">
                  <img src="@/assets/images/art3.jpg" alt="" />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="fontsize15 text-color-666">
                    انتخاب لباس های مناسب مسافرت خارجی
                  </h6>
                  <div
                    class="d-flex justify-content-between align-items-baseline"
                  >
                    <span class="fontsize12 text-color-999">شنبه 12 مرداد</span>
                    <span class="text-color-999">/</span>
                    <button class="fontsize13 text-color-theme bg-none">
                      اطلاعات بیشتر...
                    </button>
                  </div>
                </div>
              </a>
            </article>
            <article>
              <a class="d-flex mb-3" href="">
                <div class="weblog-last-articles-img ml-1">
                  <img src="@/assets/images/art2.jpg" alt="" />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="fontsize15 text-color-666">
                    انتخاب لباس های مناسب مسافرت خارجی
                  </h6>
                  <div
                    class="d-flex justify-content-between align-items-baseline"
                  >
                    <span class="fontsize12 text-color-999">شنبه 12 مرداد</span>
                    <span class="text-color-999">/</span>
                    <button class="fontsize13 text-color-theme bg-none">
                      اطلاعات بیشتر...
                    </button>
                  </div>
                </div>
              </a>
            </article>
            <article>
              <a class="d-flex mb-3" href="">
                <div class="weblog-last-articles-img ml-1">
                  <img src="@/assets/images/art3.jpg" alt="" />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="fontsize15 text-color-666">
                    انتخاب لباس های مناسب مسافرت خارجی
                  </h6>
                  <div
                    class="d-flex justify-content-between align-items-baseline"
                  >
                    <span class="fontsize12 text-color-999">شنبه 12 مرداد</span>
                    <span class="text-color-999">/</span>
                    <button class="fontsize13 text-color-theme bg-none">
                      اطلاعات بیشتر...
                    </button>
                  </div>
                </div>
              </a>
            </article>
            <article>
              <a class="d-flex mb-3" href="">
                <div class="weblog-last-articles-img ml-1">
                  <img src="@/assets/images/art2.jpg" alt="" />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="fontsize15 text-color-666">
                    انتخاب لباس های مناسب مسافرت خارجی
                  </h6>
                  <div
                    class="d-flex justify-content-between align-items-baseline"
                  >
                    <span class="fontsize12 text-color-999">شنبه 12 مرداد</span>
                    <span class="text-color-999">/</span>
                    <button class="fontsize13 text-color-theme bg-none">
                      اطلاعات بیشتر...
                    </button>
                  </div>
                </div>
              </a>
            </article>
          </section> -->
        </div>
      </div>


    </main>

    <Footer></Footer>
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
import pagination from "shetabit-laravel-vue-pagination"

import Weblog from "@/parts/Front/components/Weblog";
import mixin from "@@/core/mixins/mixins.js";
import ZPrevSvg from "@/parts/Front/components/shared/ZPrevSvg";
import ZNextSvg from "@/parts/Front/components/shared/ZNextSvg";
export default {
  name: "WeblogList",
  mixins: [mixin],
  components: {
    ZNextSvg,
    ZPrevSvg,
    Weblog,
    PagesPath,pagination
  },
  data() {
    return { search: null, spinnerSearch: false, selectedCategoryId: null };
  },
  mounted() {
    //get height of advertisting1
    const advertisting1 = document.querySelectorAll(".advertisting1")[0];
    if (advertisting1) {
      advertisting1.style.height = advertisting1.offsetWidth / 1.5 + "px";
    }

    window.addEventListener("resize", function () {
      const advertisting1 = document.querySelectorAll(".advertisting1")[0];
      if (advertisting1) {
        advertisting1.style.height = advertisting1.offsetWidth / 1.5 + "px";
      }

      // get height of articles/
      const articleImg = document.querySelectorAll(".article-img");
      for (let iImg of articleImg) {
        let imgH = iImg.offsetWidth / 1.45;
        iImg.style.height = imgH + "px";
      }
    });

    // get height of articles/
    let bodyWidth = document.body.offsetWidth;
    const articleImg = document.querySelectorAll(".article-img");
    if (bodyWidth < 767) {
      for (let iImg of articleImg) {
        let imgH = iImg.offsetWidth / 1.45;
        iImg.style.height = imgH + "px";
      }
    }
  },
  created() {
    // در صورت خالی بودن لیست پست ها اطلاعات از سمت سرور فتچ شود
    if (this.blogs == null) {
      // اگر دسته بندی انتخاب شده باشد
      if (this.$route.params.id) {
        const data = {
          category: this.$route.params.id,
        };
        // و اگر صفحه بندی نیز انتخاب شده باشد
        if (this.$route.query.page) {
          data.page = this.$route.query.page;
        }
        // ارسال درخواست
        this.$store.dispatch("front/getAllBlogFromServer", data);
      } else {
        // اگر دسته بندی انتخاب نشده باشد
        const data = {};
        // ولی اگر صفحه بندی داشت
        if (this.$route.query.page) {
          data.page = this.$route.query.page;
        }
        this.$store.dispatch("front/getAllBlogFromServer", data);
      }
    }
  },
  computed: {
    // دریافت اطلاعات از ویو ایکس برای لیست پست ها
    blogs() {
      return this.$store.getters["front/getAllBlog"];
    },
    status() {
      // برای نمایش لودر ها
      return this.$store.getters["front/getStatusAllBlog"];
    },
  },
  methods: {
    showBlogs(link) {
      if (link == null) return;
      // page=             پیدا کردن عدد مقابل کوئری
      // نمایش در یو آر ال و صدا زدن تابع فتچ
      let pageIndex = link.search("page=");
      let page = link.slice(pageIndex + 5);
      this.$router.replace({ name: "weblogList", query: { page: page } });
      // this.$route.query.page=page;
      // let data={
      //   page:page
      // };
      // if(this.$route.params.id){
      //   data.category=this.$route.params.id
      // }
      //  this.$store.dispatch("front/getAllBlogFromServer",data);
    },
    blogSearch() {
      this.spinnerSearch = true;

      if (this.search != null) {
        //با کلیک روی دکمه سرچ جستوجو در بلاگ ها صورت میگیرد
        this.$store.dispatch("front/searchInBlog", this.search);
      } else {
        // در صورت خالی بودن فوکز شود روی اینپوت
        document.getElementById("seacrchInBlogs").focus();
        this.spinnerSearch = false;
      }
    },
    // تابع زیر برای اضافه کردن متا تگ و بالا بردن سئو است
  },
  watch: {
    blogs(newVal, oldVal) {
      if (newVal != oldVal) {
        //برای غیر فعالسازی اسپینر هنگام سرچ
        this.spinnerSearch = false;
      }
    },
    "$route.params.id": {
      handler: function (val) {
        const data = {
          category: val,
        };
        if (this.$route.query.page) {
          data.page = this.$route.query.page;
        }
        this.$store.dispatch("front/getAllBlogFromServer", data);
      },
      immediate: true,
    },
    "$route.query.page": {
      handler: function (val) {
        const data = {
          page: val,
        };
        if (this.$route.params.id) {
          data.category = this.$route.params.id;
        }
        this.$store.dispatch("front/getAllBlogFromServer", data);
      },
      immediate: true,
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "description",
          content: this.blogs.posts.data
              ? this.blogs.posts.data[0].name
              : false,
        },
        {
          property: "og:title",
          content: this.blogs.posts.data[0]
              ? this.blogs.posts.data[0].name
              : false,
        },
        { name: "robots", content: "index,follow" },
      ],
    };
  },

};
</script>

<style scoped>
#seatchBlogSection {
  display: flex;
}
#hrSearch {
  margin-top: 3px;
}
#seacrchInBlogs {
  flex-grow: 10;
  background: transparent;
}
/* @media (max-width:991px){
  #mainPaginationBlog{
    display: none!important;
  }
} */
/* افزودن استایل به کامپوننت اضافه شده  */
.pagination {
  justify-content: center;
  flex-direction: row;
}

/*تعمیر قالب   */
#mainPaginationBlog {
  background: none !important;
}
#listPagination {
  background: none !important;
}
</style>
